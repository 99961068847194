<template>
    <div class="earthBox">
        <div class="ebTop">
            <p class="etBig">服务市场</p>
            <p class="etSmall">为改善和提高满足部分企业、使用人特殊需要的各项服务</p>
        </div>
        <!-- 背景图 -->
        <div class="earthBg"></div>
        <!-- 顶部总结 -->
        <div class="earthTitle">
            <div class="et1" v-if="getWebData().year">
                <div class="etNum">
                    <div class="en1" v-if="getWebData().year.toString()[0]">
                        <!-- 两个年份 -->
                        <img
                            src="http://iv.okvu.cn/vugw/img/ceNum.png"
                            :style="{ transform: `translateY(${makeYear(getWebData().year.toString()[0])})` }"
                        />
                    </div>
                    <div class="en2" v-if="getWebData().year.toString()[1]">
                        <!-- 两个年份 -->
                        <img
                            src="http://iv.okvu.cn/vugw/img/ceNum.png"
                            :style="{ transform: `translateY(${makeYear(getWebData().year.toString()[1])})` }"
                        />
                    </div>
                    <p class="en3">年</p>
                </div>
                <p class="etText">开发经验打造行业品牌</p>
            </div>
            <div class="et2">
                <div class="etNum">
                    <div class="en4">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <p class="en6">+</p>
                </div>
                <p class="etText">企业客户共同信赖</p>
            </div>
            <div class="et3">
                <div class="etNum">
                    <div class="en7">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <div class="en5">
                        <img src="http://iv.okvu.cn/vugw/img/ceNum.png" alt="" />
                    </div>
                    <p class="en6">+</p>
                </div>
                <p class="etText">客户案例真实呈现</p>
            </div>
        </div>
        <div class="earBoxs" v-if="listData.length">
            <template v-for="(el, i) in listData">
                <div class="weChat" :style="el.style" :key="el.id" @mouseover="stopAni(i)" @mouseout="openAni">
                    <div class="wcCircular"></div>
                    <div class="wcText">
                        <p>100+</p>
                        <p>{{ el.category_title }}</p>
                    </div>
                    <!--  -->
                    <div class="wcCompany" :class="theShow == i ? `showBoxs wcCompany${i}` : `wcCompany${i}`">
                        <div class="cpLogo">
                            <img :src="el.logo" />
                        </div>
                        <div class="cpText">
                            <p class="cpName">{{ el.signing }}</p>
                            <p class="cpData">{{ el.signing_time }}</p>
                        </div>
                        <img src="http://iv.okvu.cn/vugw/img/ceg.png" class="cpBg" :class="'cpBg' + i" />
                    </div>
                </div>
            </template>
        </div>
        <!-- 了解更多 -->
        <div class="moreBtn" @click="goPage">
            <p class="mb1">了解更多 ></p>
            <p class="mb2">了解更多 ></p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "CustomerEarth",
    inject: ["getWebData"],
    data() {
        return {
            theShow: 7,
            Ani: null,
            listData: [
                {
                    category_title: "营销",
                    style: {
                        bottom: "28px",
                        left: "20%",
                    },
                },
                {
                    category_title: "推广",
                    style: {
                        bottom: "114px",
                        left: "24%",
                    },
                },
                {
                    category_title: "网站",
                    style: {
                        bottom: "200px",
                        left: "32%",
                    },
                },
                {
                    category_title: "小程序",
                    style: {
                        bottom: "253px",
                        left: "42%",
                    },
                },
                {
                    category_title: "应用",
                    style: {
                        bottom: "260px",
                        left: "54%",
                    },
                },
                {
                    category_title: "智能",
                    style: {
                        bottom: "209px",
                        left: "66%",
                    },
                },
                {
                    category_title: "虚拟",
                    style: {
                        bottom: "118px",
                        left: "75%",
                    },
                },
                {
                    category_title: "设计",
                    style: {
                        bottom: "28px",
                        left: "79%",
                    },
                },
            ],
        };
    },
    created() {
        axios.get("/api/project/eightshow").then(({ data }) => {
            this.listData.forEach(e => {
                let obj = data.find(el => el.category_title == e.category_title);
                // 合并
                Object.assign(e, obj);
            });
            this.$nextTick(() => {
                this.openAni();
            });
        });
    },
    beforeDestroy() {
        clearInterval(this.Ani);
        this.Ani = null;
    },
    methods: {
        goPage() {
            this.$router.push("/case");
        },
        // 停止自动弹窗
        stopAni(i) {
            clearInterval(this.Ani);
            this.theShow = i;
            this.Ani = null;
        },
        // 开启自动弹窗
        openAni() {
            let _this = this;
            this.Ani = setInterval(() => {
                function sj() {
                    // 0~7随机数
                    let num = Math.floor(Math.random() * 8);
                    if (num == _this.theShow) return sj();
                    return num;
                }
                this.theShow = sj();
            }, 1500);
        },
        // 处理年份
        makeYear(num) {
            if (typeof num == "string") {
                num = Number(num);
                return `-${num * 70}px`;
            }
            return "800px";
        },
    },
};
</script>

<style lang="less" scoped>
.earthBox {
    width: 100%;
    height: 670px;
    margin-top: 30px;
    position: relative;
    text-align: right;
    overflow: hidden;
    .ebTop {
        width: 50%;
        // height: 80px;
        margin: 0 auto;
        text-align: center;
        .etBig {
            width: 100%;
            height: 90%;
            line-height: 50px;
            font-size: 40px;
        }
        .etSmall {
            width: 100%;
            height: 27px;
            line-height: 27px;
            font-size: 16px;
            margin-top: 10px;
        }
    }
    // 背景图
    .earthBg {
        width: 100%;
        height: 88%;
        background: url("https://iv.okvu.cn/vugw/img/earBg.jpg") no-repeat center;
        z-index: -1;
        position: absolute;
    }
    // 顶部总结
    .earthTitle {
        width: 60%;
        height: 130px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
        .et1,
        .et2,
        .et3 {
            width: 170px;
            height: 130px;

            .etNum {
                width: 100%;
                height: 75px;
                line-height: 75px;
                font-size: 56px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                .en1 {
                    width: 35px;
                    height: 70px;
                    float: left;
                    overflow: hidden;
                    img {
                        animation-name: the-num;
                        animation-delay: 0.5s;
                        animation-duration: 0.8s;
                        // transform: translateY(80px);
                    }
                }
                .en2 {
                    width: 35px;
                    height: 70px;
                    float: left;
                    overflow: hidden;
                    img {
                        animation-name: the-num;
                        animation-delay: 0.8s;
                        animation-duration: 0.8s;
                        // transform: translateY(-488px);
                    }
                }
                .en3 {
                    width: 60px;
                    height: 70px;
                    line-height: 70px;
                    float: left;
                    color: #7781f1;
                    font-weight: 900;
                    font-size: 56px;
                }
                .en4 {
                    width: 35px;
                    height: 70px;
                    float: left;
                    overflow: hidden;
                    img {
                        animation-name: the-num;
                        animation-delay: 0.9s;
                        animation-duration: 0.8s;
                        transform: translateY(-140px);
                    }
                }
                .en5 {
                    width: 35px;
                    height: 70px;
                    float: left;
                    overflow: hidden;
                    img {
                        animation-name: the-num;
                        animation-delay: 1s;
                        animation-duration: 0.8s;
                    }
                }
                .en6 {
                    width: 23px;
                    height: 44px;
                    line-height: 44px;
                    float: left;
                    color: #7781f1;
                    font-weight: 900;
                    font-size: 40px;
                }
                .en7 {
                    width: 35px;
                    height: 70px;
                    float: left;
                    overflow: hidden;
                    img {
                        animation-name: the-num;
                        animation-delay: 1.1s;
                        animation-duration: 0.8s;
                        transform: translateY(-210px);
                    }
                }
            }
            .etText {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #7781f1;
            }
        }
        .et2,
        .et3 {
            min-width: 200px;
        }
    }
    @keyframes the-num {
        0% {
            transform: translateY(0px);
        }
        20% {
            transform: translateY(-130px);
        }
        40% {
            transform: translateY(-230px);
        }
        60% {
            transform: translateY(-430px);
        }
        80% {
            transform: translateY(-630px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    .earBoxs {
        max-width: 1280px;
        height: 300px;
        margin: 0 auto;
        margin-top: 243px;
        position: relative;
    }
    .showBoxs {
        opacity: 1 !important;
        transform: scale(1) !important;
        z-index: 9;
    }
    @keyframes spot1 {
        0% {
            transform: scale(0.5);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }

    .weChat {
        position: absolute;
        // bottom: 28px;
        // left: 20%;
        color: white;
        font-weight: 900;
        cursor: pointer;
        .wcCircular {
            width: 20px;
            height: 20px;
            margin-top: 10px;
            // background: linear-gradient(to right, #7781f1, #7781f1e0);
            border-radius: 50%;
            float: left;
            position: relative;
        }
        .wcCircular::before {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(119, 129, 241, 0.3);
            animation: spot1 1s infinite linear;
            content: "";
            position: absolute;
            transform-origin: 50% 50%;
            overflow: hidden;
            border-radius: 50%;
        }
        .wcCircular::after {
            width: 60%;
            height: 60%;
            left: 20%;
            top: 20%;
            background-color: rgba(119, 129, 241, 0.5);
            animation: spot1 1s infinite linear;
            content: "";
            position: absolute;
            transform-origin: 50% 50%;
            overflow: hidden;
            border-radius: 50%;
        }
        .wcText {
            width: 100px;
            height: 50px;
            float: left;
            margin-left: 5px;
            position: absolute;
            top: -4px;
            left: -120px;

            p {
                width: 100%;
                height: 50%;
                line-height: 25px;
                color: black;
            }
        }
        // 微信合作公司
        .wcCompany {
            width: 344px;
            height: 170px;
            position: absolute;
            z-index: 9;
            border-radius: 50px;
            opacity: 0;
            // background: url("vu818.com/vu818com/img/images/ball.png") no-repeat top;
            transition: 0.5s;
            transform: scale(0.5);
            text-align: left;
            .cpLogo {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin-top: 8px;
                float: left;
                overflow: hidden;
                border: 4px solid #33d59c;
                box-sizing: border-box;
                background-color: white;
                position: relative;
                img {
                    width: 255%;
                    height: 100%;
                    object-fit: contain;
                    background-color: white;
                }
            }
            .cpText {
                width: 279px;
                height: 80px;
                margin-left: 45px;
                margin-top: 8px;
                font-size: 14px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                background: linear-gradient(to right, #7781f1, #7781f1e0);
                .cpName {
                    width: 90%;
                    height: 50px;
                    padding-top: 10px;
                    padding-left: 40px;
                    box-sizing: border-box;
                    line-height: 20px;
                    font-size: 12px;
                }
                .cpData {
                    width: 100%;
                    height: 20px;
                    padding-top: 5px;
                    padding-left: 40px;
                    line-height: 20px;
                }
            }
            .cpBg {
                position: absolute;
                z-index: -1;
            }
            .cpBg0 {
                top: 0;
                right: 0;
            }
            .cpBg1 {
                top: 0;
                right: 0;
            }
            .cpBg2 {
                top: 0;
                right: 0;
            }
            .cpBg3 {
                top: 0;
                right: 0;
            }
            .cpBg4 {
                top: 0;
                left: -20px;
                transform: rotateY(180deg);
            }
            .cpBg5 {
                top: 0;
                left: -20px;
                transform: rotateY(180deg);
            }
            .cpBg6 {
                top: 0;
                left: -20px;
                transform: rotateY(180deg);
            }
            .cpBg7 {
                top: 0;
                left: -20px;
                transform: rotateY(180deg);
            }
        }
        .wcCompany0 {
            bottom: -35px;
            right: 21px;
        }
        .wcCompany1 {
            bottom: -35px;
            right: 21px;
        }
        .wcCompany2 {
            bottom: -35px;
            right: 21px;
        }
        .wcCompany3 {
            bottom: -35px;
            right: 21px;
        }
        .wcCompany4 {
            bottom: -35px;
            right: -360px;
        }
        .wcCompany5 {
            bottom: -22px;
            right: -363px;
        }
        .wcCompany6 {
            bottom: -35px;
            right: -364px;
        }
        .wcCompany7 {
            bottom: -25px;
            right: -370px;
        }
    }
    .weChat:hover {
        .wcText {
            color: #7781f1;
        }
        .wcCompany {
            opacity: 1;
            transform: scale(1);
        }
    }
    // -------------------------

    .moreBtn {
        width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(to right, #7781f1, #7781f1e0);
        cursor: pointer;
        .mb1 {
            color: #eee;
            font-weight: 900;
            transition: 0.5s;
        }
        .mb2 {
            color: #eee;
            font-weight: 900;
            position: absolute;
            top: -50px;
            left: 60px;
            opacity: 0;
            transition: 0.5s;
        }
    }
    .moreBtn:hover {
        .mb1 {
            transform: translateY(40px);

            opacity: 0;
        }
        .mb2 {
            transform: translateY(50px);

            opacity: 1;
        }
    }
}
</style>

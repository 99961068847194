<template>
    <!-- 产品简介 -->
    <div class="productBox">
        <ul>
            <li @click="goPage(1)">
                <div class="productLogo">
                    <img class="plIcon" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">品牌型网站设计</p>
                    <p class="pnSmall">展示企业CI、传播品牌文化、提高品牌知名度。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[0]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                    <div class="pmTitle">品牌型网站设计</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(2)">
                <div class="productLogo">
                    <img class="plIcon2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">智能硬件</p>
                    <p class="pnSmall">获得销售机会，高效接待，提高当前实际销售额。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[1]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg2" />
                    </div>
                    <div class="pmTitle">智能硬件</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(3)">
                <div class="productLogo">
                    <img class="plIcon3" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">微信广告</p>
                    <p class="pnSmall">让产品和服务恰好出现，让品牌就像你身边的朋友。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[2]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg3" />
                    </div>
                    <div class="pmTitle">微信广告</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(4)">
                <div class="productLogo">
                    <img class="plIcon4" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">一物一码</p>
                    <p class="pnSmall">让产品更安全，让用户使用更放心。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[3]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg4" />
                    </div>
                    <div class="pmTitle">一物一码</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(5)">
                <div class="productLogo2">
                    <img class="plIcon5" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName2">
                    <p class="pnBig">集团/上市公司网站制作</p>
                    <p class="pnSmall">安全的多站点网站群管理、严谨的内容维护和舆情管理。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[4]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg5" />
                    </div>
                    <div class="pmTitle">集团/上市公司网站制作</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(6)">
                <div class="productLogo">
                    <img class="plIcon6" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">威有云服务</p>
                    <p class="pnSmall">金牌服务商专业落地服务，服务响应更及时。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[5]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg6" />
                    </div>
                    <div class="pmTitle">威有云服务</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(7)">
                <div class="productLogo">
                    <img class="plIcon7" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">SEO全网推</p>
                    <p class="pnSmall">抢占各大搜索引擎首页、商贸平台，提升浏览量，访客量、订单量。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[6]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg7" />
                    </div>
                    <div class="pmTitle">SEO全网推</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
            <li @click="goPage(8)">
                <div class="productLogo">
                    <img class="plIcon8" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="productName">
                    <p class="pnBig">手机网站</p>
                    <p class="pnSmall">好的手机站/微站，企业的使用价值完胜APP，传播方便，获得容易。</p>
                </div>
                <!-- 遮罩层 -->
                <div class="prMask" :style="{ 'background-image': `url(https://iv.okvu.cn/vugw/img/${bgList[7]}.png)` }">
                    <div class="pmBg">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg8" />
                    </div>
                    <div class="pmTitle">手机站.微网站</div>
                    <div class="pmBtn">
                        <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="pbImg" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Product",
    data() {
        return {
            bgList: ["ppx", "jtss", "znyj", "vuy", "yxtg", "seo", "ywym", "sjwz"],
        };
    },
    methods: {
        goPage(i) {
            switch (i) {
                case 1:
                    this.$router.push("/proContent");
                    break;
                case 2:
                    // this.$router.push("/proContent2");
                    this.$router.push("/hsContent18");
                    break;
                case 3:
                    this.$router.push("/proContent3");
                    break;
                case 4:
                    this.$router.push("/proContent4");
                    break;
                case 5:
                    this.$router.push("/proContent5");
                    break;
                case 6:
                    this.$router.push("/proContent6");
                    break;
                case 7:
                    this.$router.push("/searchEg");
                    break;
                case 8:
                    this.$router.push("/thePhone");
                    break;
            }
        },
    },
};
</script>

<style lang="less">
.productBox {
    // max-width: 1280px;
    height: 300px;
    margin: 0 auto;
    // margin-top: 60px;
    text-align: left;
    ul {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
            width: 24%;
            height: 144px;
            // border: 1px solid gainsboro;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 10px;
            transition: 0.4s;
            .productLogo {
                width: 70px;
                height: 80px;
                margin-top: 30px;
                margin-left: 5px;
                margin-right: 10px;
                float: left;
                text-align: center;
                position: relative;
                overflow: hidden;
                transition: 0.4s;
                .plIcon {
                    position: absolute;
                    top: -525px;
                    left: 8px;
                }
                .plIcon2 {
                    position: absolute;
                    top: -525px;
                    left: -330px;
                }
                .plIcon3 {
                    position: absolute;
                    top: -525px;
                    left: -119px;
                }
                .plIcon4 {
                    position: absolute;
                    top: -525px;
                    left: -187px;
                }
                .plIcon6 {
                    position: absolute;
                    top: -525px;
                    left: -257px;
                }
                .plIcon7 {
                    position: absolute;
                    top: -525px;
                    left: -410px;
                }
                .plIcon8 {
                    position: absolute;
                    top: -525px;
                    left: -487px;
                }
            }
            .productLogo2 {
                width: 70px;
                height: 80px;
                margin-top: 30px;
                margin-left: 10px;
                float: left;
                text-align: center;
                position: relative;
                overflow: hidden;
                transition: 0.4s;
                .plIcon5 {
                    position: absolute;
                    top: -525px;
                    left: -57px;
                }
            }
            .productName,
            .productName2 {
                width: 70%;
                height: 70%;
                margin-left: 2%;
                margin-top: 20px;
                float: left;
                transform: scale(1);
                transition: 0.4s;
                .pnBig {
                    width: 100%;
                    height: 50%;
                    font-size: 19px;
                    font-weight: 900;
                    line-height: 50px;
                }
                .pnSmall {
                    width: 100%;
                    height: 50%;
                    line-height: 20px;
                    font-size: 13px;
                    color: gray;
                }
            }
            .productName2 {
                margin-left: 4%;
            }
            .prMask {
                width: 100%;
                height: 100%;
                line-height: 100px;
                opacity: 0;
                position: absolute;
                top: 0;
                border-radius: 10px;
                // background: linear-gradient(to right, #7781f1,#7781f1e0);
                // background-color: #a872fe;
                background-color: rgb(243, 241, 241, 0.5);
                font-weight: 900;
                overflow: hidden;

                .pmBg {
                    width: 167px;
                    height: 120px;
                    position: absolute;
                    top: 15px;
                    left: 70px;
                    z-index: 0;
                    transform: scale(0.5);
                    transition: 0.4s;
                    overflow: hidden;
                    display: none;

                    img {
                        width: 3500px;
                        height: 5000px;
                    }
                    .pbImg {
                        position: absolute;
                        top: -1190px;
                        left: 20px;
                        opacity: 0.5;
                    }
                    .pbImg2 {
                        position: absolute;
                        top: -1190px;
                        left: -780px;
                        opacity: 0.5;
                    }
                    .pbImg3 {
                        position: absolute;
                        top: -1190px;
                        left: -250px;
                        opacity: 0.5;
                    }
                    .pbImg4 {
                        position: absolute;
                        top: -1190px;
                        left: -425px;
                        opacity: 0.5;
                    }
                    .pbImg5 {
                        width: 3700px;
                        height: 5000px;
                        position: absolute;
                        top: -1190px;
                        left: -120px;
                        opacity: 0.5;
                    }
                    .pbImg6 {
                        position: absolute;
                        top: -1190px;
                        left: -605px;
                        opacity: 0.5;
                    }
                    .pbImg7 {
                        position: absolute;
                        top: -1190px;
                        left: -935px;
                        opacity: 0.5;
                    }
                    .pbImg8 {
                        position: absolute;
                        top: -1190px;
                        left: -1100px;
                        opacity: 0.5;
                    }
                }
                .pmTitle {
                    width: 90%;
                    height: 50%;
                    margin: 0 auto;
                    font-size: 25px;
                    text-align: center;
                    z-index: 3;
                    transform: translate(20px, 20px);
                    transition: 0.4s;
                }
                .pmBtn {
                    width: 15%;
                    height: 20%;
                    box-shadow: 1px 1px 10px 1px gray;
                    margin: 0 auto;
                    margin-top: 20px;
                    text-align: center;
                    border-radius: 50px;
                    position: relative;
                    z-index: 3;
                    transform: translate(20px, 20px);
                    transition: 0.4s;
                    overflow: hidden;
                    background-color: white;
                    .pbImg {
                        width: 2000px;
                        position: absolute;
                        top: -133px;
                        right: -230px;
                    }
                    .icon-a-youjiantouzhixiangyoujiantou {
                        font-size: 25px;
                        display: block;
                        position: absolute;
                        top: -35px;
                        left: 25px;
                    }
                }
            }
        }
        li:hover {
            box-shadow: 1px 1px 10px 1px gray;
            transform: scale(1.03);
            z-index: 7;
            .productLogo {
                transform: scale(0);
            }
            .productName {
                transform: scale(0);
            }
            .prMask {
                opacity: 1;
                // background: url(https://iv.okvu.cn/vugw/img/ppx.png) no-repeat center;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
                .pmBg {
                    transform: scale(1);
                }
                .pmTitle,
                .pmBtn {
                    transform: translate(0px, 0px);
                }
            }
        }
    }
}
</style>

<template>
    <div class="hDevelopmentBox">
        <!-- 标题 -->
        <div class="hdTitle">
            <p class="htBig">发展历程</p>
            <p class="htSmall">
                严字当头、奋发进取、为威有争光的志气<span @click="$router.push('/adevelop')"> 查看全部历程 ></span>
            </p>
        </div>
        <div class="hdBox" v-if="dataList.length">
            <!-- 左边按钮 -->
            <div class="slLeftBtn">
                <el-icon
                    style="font-size: 400%; color: black; font-weight: 200"
                    class="el-icon-arrow-left"
                    @click.native="prev"
                    v-show="current != 0 && dataList.length > 6"
                ></el-icon>
            </div>
            <!-- 发展历程 -->
            <div class="hdCourseBox">
                <div class="topCourse">
                    <ul ref="topul">
                        <li v-for="el in topList" :key="el.id" class="tcLi">
                            <div class="content" :class="{ tcActive: isActive === el.id }" @mousemove="isActive = el.id">
                                <p class="tcYear">{{ el.time }}</p>
                                <p class="tcText">{{ el.intro }}</p>
                            </div>
                            <div class="line"></div>
                        </li>
                    </ul>
                </div>
                <!-- <hr /> -->
                <div class="hr" :style="{ width: ` calc( 10% + ${dataList.length * 15}%)` }"></div>
                <div class="topCourse" style="left: 12.7%">
                    <ul ref="bottomul">
                        <li v-for="el in bottomList" :key="el.id" class="tcLi">
                            <div class="line"></div>
                            <div class="content" :class="{ tcActive: isActive === el.id }" @mousemove="isActive = el.id">
                                <p class="tcYear">{{ el.time }}</p>
                                <p class="tcText">{{ el.intro }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 右边按钮 -->
            <div class="slRightBtn">
                <el-icon
                    style="font-size: 400%; color: black; font-weight: 200"
                    class="el-icon-arrow-right"
                    @click.native="next"
                    v-show="dataList.length - current * 2 > 6"
                ></el-icon>
            </div>
        </div>
        <img v-else src="https://iv.okvu.cn/vugw/img/zwsj.png" style="width: 300px" />
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Hdevelopment",
    data() {
        return {
            isActive: 1,
            dataList: [],
            left: 0,
            current: 0,
        };
    },
    created() {
        axios.get("/api/development_history/history").then(({ data }) => {
            this.dataList = data.filter(e => e.id !== 1);
        });
    },
    methods: {
        prev() {
            let topul = this.$refs.topul;
            let bottomul = this.$refs.bottomul;
            this.left += 33;
            topul.style.left = `${this.left}%`;
            bottomul.style.left = `${this.left}%`;
            this.current--;
        },
        next() {
            let topul = this.$refs.topul;
            let bottomul = this.$refs.bottomul;
            this.left -= 33;
            topul.style.left = `${this.left}%`;
            bottomul.style.left = `${this.left}%`;
            this.current++;
        },
    },
    computed: {
        topList() {
            if (!this.dataList.length) return [];
            // 奇数
            return this.dataList
                .map((el, i) => {
                    if (!(i % 2)) return { ...el };
                    return null;
                })
                .filter(el => el);
        },
        bottomList() {
            if (!this.dataList.length) return [];
            // 偶数
            return this.dataList
                .map((el, i) => {
                    if (i % 2) return { ...el };
                    return null;
                })
                .filter(el => el);
        },
    },
};
</script>

<style lang="less" scoped>
.hDevelopmentBox {
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 30px;
    overflow: hidden;
    min-height: 500px;
    // width: calc((1440px * 0.9 * 0.8) + 10%);
    // 标题
    .hdTitle {
        width: 70%;
        margin: 0 auto;
        // margin-top: 30px;
        text-align: center;
        .htBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 36px;
        }
        .htSmall {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            margin-top: 10px;
            span {
                color: #7781f1;
                cursor: pointer;
            }
        }
    }
    .hdBox {
        max-width: 1440px;
        height: 500px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        // 左边按钮
        .slLeftBtn {
            width: 5%;
            height: 80px;
            // line-height: 100px;
            text-align: center;
            // margin-top: 198px;
            // float: left;
            transition: 1s;
            overflow: hidden;
            position: relative;
            i {
                cursor: pointer;
            }
        }
        // 右边按钮
        .slRightBtn {
            width: 5%;
            height: 80px;
            // line-height: 80px;
            text-align: center;
            // float: left;
            // margin-top: 200px;
            // margin-top: 198px;
            overflow: hidden;
            position: relative;
            i {
                cursor: pointer;
            }
        }

        // 发展历程
        .hdCourseBox {
            max-width: 90%;
            height: 450px;
            // margin: 0 auto;
            margin-top: 30px;
            // float: left;
            .hr {
                height: 1px;
                // width: 100%;
                max-width: 100%;
                background-color: black;
            }
            // 上部分
            .topCourse {
                width: 80%;
                margin: 0 auto;
                overflow: hidden;
                position: relative;
                ul {
                    // width: 1800px;
                    position: relative;
                    left: 0px;
                    min-width: 100vw;
                    transition: 0.5s;
                    transform: translateX(0px);
                    display: flex;
                    flex-wrap: nowrap;
                    .tcLi {
                        margin-right: 70px;
                        width: 15%;
                        max-width: 250px;
                        // flex: 1;
                        .content {
                            // width: 250px;
                            height: 150px;
                            width: 100%;
                            border-radius: 20px;
                            transition: 0.4s;
                            position: relative;

                            .tcYear {
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                font-size: 30px;
                                font-weight: 900;
                            }
                            .tcText {
                                width: 95%;
                                height: 100px;
                                font-size: 15px;
                                line-height: 25px;
                                overflow-x: hidden;
                                text-align: left;
                            }
                        }
                        .line {
                            height: 50px;
                            background-color: black;
                            width: 1px;
                            margin: 0 auto;
                            position: relative;
                        }
                    }
                    .tcActive {
                        // width: 300px;
                        padding-left: 10px;
                        color: white;
                        background: linear-gradient(to right, #7781f1, #7781f1e0);
                    }
                }
            }
            // 分割线
            ::-webkit-scrollbar {
                width: 3px;
                background-color: #eee;
                padding-right: 5px;
                border-radius: 5px;
            }
            ::-webkit-scrollbar-thumb {
                width: 3px;
                border-radius: 10px;
                background-color: #7781f1;
            }
        }
    }
}
</style>

<template>
    <div style="width: 100%; margin-top: 40px; text-align: center">
        <div>
            <p style="font-size: 40px">热门服务</p>
            <p style="margin: 10px 0 20px">最受欢迎的几种服务方式</p>
        </div>
        <el-row :gutter="10" style="width: 99.9%; margin: 0 auto; height: 350px">
            <el-col :span="mosLive == index + 1 ? 9 : 5" v-for="(item, index) in imageArr" :key="item.title" ref="myCol">
                <el-card style="height: 340px; position: relative; border-radius: 10px">
                    <img
                        :src="item.url"
                        style="width: 102%; opacity: 0.3; position: absolute; top: 0; left: 0"
                        v-if="mosLive == index + 1"
                    />
                    <div
                        :class="index == 1 ? 'imgDiv' : index == 2 ? 'imgDiv2' : ''"
                        style="width: 70px; height: 60px; position: relative; overflow: hidden; left: -10px"
                    >
                        <img
                            :class="mosLive == index + 1 ? item.className[1] : item.className[0]"
                            src="http://iv.okvu.cn/vugw/img/vu818.png"
                        />
                    </div>
                    <div style="display: flex; width: 100%">
                        <div :class="mosLive == index + 1 ? 'width1' : 'width2'" style="text-align: left; color: #2c3e50">
                            <p style="font-weight: 500; font-size: 30px">{{ item.title }}</p>
                            <p style="font-size: 18px">{{ item.enName }}</p>
                            <p>{{ item.content }}</p>
                        </div>
                    </div>
                    <div
                        style="width: 30%; height: 100%; position: absolute; left: 70%; top: 0; /* float: right; */"
                        v-if="mosLive == index + 1"
                    >
                        <ul style="z-index: 99; position: relative" :class="'ulCss' + index" class="HotServices_zou_ul">
                            <li
                                class="liHover"
                                style="
                                    box-shadow: 1px 1px 10px 1px #eee;
                                    width: 70%;
                                    padding-top: 3px;
                                    height: 30px;
                                    border: 1px solid #f0f0f0;
                                    margin: 15px auto 0;
                                    border-radius: 10px;
                                    cursor: pointer;
                                    color: #fff;
                                "
                                @click="goPages(itemT)"
                                v-for="itemT in item.childrenArr"
                                :key="itemT"
                            >
                                {{ itemT }}
                            </li>
                        </ul>
                        <span
                            style="
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                background-color: black;
                                opacity: 0.6;
                            "
                        ></span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mosLive: 1,
            setTimeoutStatus: null,
            imageArr: [
                {
                    url: "https://iv.okvu.cn/vugw/img/hhot1.jpg",
                    title: "网站",
                    className: ["icons1", "icons12"],
                    content:
                        "网站建设综合服务，是为企业提供一对一的定制建站方案，帮助企业在网络中全面展示品牌优势，扩大商业影响力",
                    enName: "WEBSITE",
                    childrenArr: ["官网", "品牌", "外贸", "营销型", "行业门户", "数字化转型"],
                },
                {
                    url: "https://iv.okvu.cn/vugw/img/hhot2.jpg",
                    title: "应用",
                    className: ["icons2", "icons22"],
                    content:
                        "移动智能终端用户激增，带来了广阔的商业契机。移动应用开发服务，为企业深度挖掘移动流量，转流量为消费者提供了有力支持",
                    enName: "MOBILE",
                    childrenArr: ["APP开发", "智能硬件", "手机网站", "其他开发", "小程序"],
                },
                {
                    url: "https://iv.okvu.cn/vugw/img/hhot4.jpg",
                    title: "电商",
                    className: ["icons3", "icons32"],
                    content:
                        "电商平台开发，为电商企业打开互联网销新渠道，利用平台的数字化信息网络，企业可实现线上交易运营，获得更广阔的商业待遇",
                    enName: "BUSINESS",
                    childrenArr: ["电商", "微商城"],
                },
                {
                    url: "https://iv.okvu.cn/vugw/img/hhot4.jpg",
                    title: "营销",
                    className: ["icons4", "icons42"],
                    content:
                        "网络营销服务通过网站推广，互联网推广等技术形式，将企业或其产品更好更快地展现在客户眼前，增强企业在网络中的有效宣传",
                    enName: "MARKETIN",
                    childrenArr: ["短视频", "代运营", "广告屏", "SEM", "朋友圈"],
                },
            ],
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.setFor();
            var myColArr = this.$refs.myCol;
            myColArr.map((item, index) => {
                item.$el.addEventListener("mouseover", () => {
                    this.mosLive = index + 1;
                    clearInterval(this.setTimeoutStatus);
                });
                item.$el.addEventListener("mouseout", () => {
                    //   clearInterval(this.setTimeoutStatus);
                    this.setFor();
                });
            });
            //   myCol.addEventListener("mouseover", () => {
            //     console.log("11111");
            //   });
        });
    },
    methods: {
        setFor() {
            // this.setTimeoutStatus = setInterval(() => {
            //     if (this.mosLive == 4) {
            //         this.mosLive = 1;
            //     } else {
            //         this.mosLive += 1;
            //     }
            // }, 2000);
        },
        // mouseoverCard(val) {
        //   this.mosLive = val;
        //   clearInterval(this.setTimeoutStatus);
        // },
        goPages(val) {
            switch (val) {
                case "官网":
                    this.$router.push("/hsContent");
                    break;
                case "APP开发":
                    this.$router.push("/theApp");
                    break;
                case "微商城":
                    this.$router.push("/hsContent2");
                    break;
                case "代运营":
                    this.$router.push("/hsContent3");
                    break;
                case "品牌":
                    this.$router.push("/hsContent12");
                    break;
                case "外贸":
                    this.$router.push("/hsContent13");
                    break;
                case "营销型":
                    this.$router.push("/hsContent14");
                    break;
                case "行业门户":
                    this.$router.push("/hsContent15");
                    break;
                case "数字化转型":
                    this.$router.push("/hsContent44");
                    break;
                case "手机网站":
                    this.$router.push("/hsContent17");
                    break;
                case "其他开发":
                    this.$router.push("/hsContent21");
                    break;
                case "小程序":
                    this.$router.push("/hsContent22");
                    break;
                case "电商":
                    this.$router.push("/hsContent31");
                    break;
                case "广告屏":
                    this.$router.push("/hsContent43");
                    break;
                case "SEM":
                    this.$router.push("/hsContent41");
                    break;
                case "朋友圈":
                    this.$router.push("/hsContent42");
                    break;
                case "智能硬件":
                    this.$router.push("/hsContent18");
                    break;
                case "短视频":
                    this.$router.push("/hsContent45");
                    break;
            }
        },
    },
};
</script>
<style lang="less">
.HotServices_zou_ul {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
    height: 100%;
    justify-content: center;
}
.liHover:hover {
    color: black !important;
}
.ulCss0 {
    margin-top: 20%;
}
.ulCss1 {
    margin-top: 30%;
}
.ulCss2 {
    margin-top: 70%;
}
.ulCss3 {
    margin-top: 30%;
}
.width1 {
    width: 70%;
}
.width2 {
    width: 100%;
}
.imgDiv {
    width: 44px !important;
}
.imgDiv2 {
    width: 70px !important;
}
.icons1 {
    position: absolute;
    top: -820px;
    left: -982px;
}
.icons12 {
    position: absolute;
    top: -760px;
    left: -982px;
}
.icons3 {
    position: absolute;
    top: -820px;
    left: -1110px;
}
.icons32 {
    position: absolute;
    top: -760px;
    left: -1110px;
}
.icons4 {
    position: absolute;
    top: -820px;
    left: -1172px;
}
.icons42 {
    position: absolute;
    top: -760px;
    left: -1172px;
}
.icons2 {
    position: absolute;
    top: -820px;
    left: -1060px;
}
.icons22 {
    position: absolute;
    top: -760px;
    left: -1060px;
}
</style>

<template>
    <div>
        <div
            class="snow"
            :class="'dh' + Math.floor(sj(1, 5))"
            v-for="el in 350"
            :key="el"
            :style="{
                width: `${Math.floor(sj(5, 30))}px`,
                left: `${Math.floor(sj(0, 100))}vw`,
                // 动画时间
                'animation-duration': `${sj(15, 35)}s`,
                // 动画贝塞尔曲线
                'animation-timing-function': `cubic-bezier(${sj(0, 1)}, ${sj(0, 1)}, ${sj(0, 1)}, 1)`,
            }"
            ref="snow"
        >
            <img src="@/assets/img/snow.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        sj(min, max) {
            let n = Math.random() * (max - min) + min;
            return n;
        },
    },
};
</script>

<style lang="less" scoped>
.snow {
    position: fixed;
    top: -30vh;
    z-index: 3000;
    // 循环播放
    animation-iteration-count: infinite;
    img {
        width: 100%;
        height: auto;
    }
}
.dh1 {
    animation-name: an1;
    @keyframes an1 {
        0% {
            transform: translate(0vw, 0vh);
        }
        100% {
            transform: translate(50vw, 150vh);
        }
    }
}
.dh2 {
    animation-name: an2;

    @keyframes an2 {
        0% {
            transform: translate(0vw, 0vh);
        }
        100% {
            transform: translate(-50vw, 150vh);
        }
    }
}
.dh3 {
    animation-name: an3;
    transform-origin: 0 -20vh;
    @keyframes an3 {
        0% {
            transform: translate(0vw, 0vh) rotate(0deg);
        }
        100% {
            transform: translate(0vw, 150vh) rotate(45deg);
        }
    }
}
.dh4 {
    animation-name: an4;
    transform-origin: 0 -20vh;
    @keyframes an3 {
        0% {
            transform: translate(0vw, 0vh) rotate(0deg);
        }
        100% {
            transform: translate(0vw, 150vh) rotate(-45deg);
        }
    }
}
</style>

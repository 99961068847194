import { render, staticRenderFns } from "./denglong.vue?vue&type=template&id=7487191c&scoped=true&"
var script = {}
import style0 from "./denglong.vue?vue&type=style&index=0&id=7487191c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7487191c",
  null
  
)

export default component.exports
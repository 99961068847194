<template>
    <div class="home">
        <!-- 节日特效 -->
        <festival></festival>
        <!-- 轮播图 -->
        <TopSwiper></TopSwiper>
        <!-- 开屏广告 -->
        <HomeMask></HomeMask>
        <!-- 热门服务 -->
        <HotServices></HotServices>
        <!-- 地球图 -->
        <CustomerEarth></CustomerEarth>
        <!-- 为什么选择威有 -->
        <AlcOne></AlcOne>
        <!-- 产品简介 -->
        <Product></Product>
        <!-- 丰富的解决方案 聚焦产业核心场景 -->
        <Product2></Product2>
        <!-- App应用领域 -->
        <AppField></AppField>
        <!-- 威有合作伙伴 -->
        <PartCase></PartCase>
        <!-- 发展历程 -->
        <Hdevelopment></Hdevelopment>
        <!-- 威有动静 -->
        <Movement></Movement>
        <!-- 贴心专业诊断 专家报价 -->
        <Price></Price>
    </div>
</template>

<script>
import TopSwiper from "../components/content/Homes/TopSwiper/TopSwiper.vue";
import HotServices from "../components/content/Homes/HotServices/HotServices.vue";
import CustomerEarth from "../components/content/Homes/CustomerEarth/CustomerEarth.vue";
import Product from "../components/content/Homes/Product/Product.vue";
import Product2 from "../components/content/Homes/Product/Product2.vue";
import AppField from "../components/content/Homes/AppField/AppField.vue";
import PartCase from "../components/content/Homes/PartCase/PartCase.vue";
import Hdevelopment from "../components/content/Homes/Hdevelopment/Hdevelopment.vue";
import Movement from "../components/content/Homes/Movement/Movement.vue";
import Price from "../components/content/Homes/Price/Price.vue";
import HomeMask from "../components/content/Homes/HomeMask/HomeMask.vue";
import AlcOne from "../components/content/TheSoftware/TheApplet/AlContent/AlcOne.vue";
import Festival from "./Festival.vue";

export default {
    name: "Home",
    components: {
        TopSwiper,
        HotServices,
        CustomerEarth,
        Product,
        Product2,
        AppField,
        PartCase,
        Hdevelopment,
        Movement,
        Price,
        HomeMask,
        AlcOne,
        Festival,
    },
    data() {
        return {};
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
.home {
    min-width: 960px;
}
</style>

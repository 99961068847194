<template>
    <div v-if="getAdvData(2).children">
        <div class="homeMask" @mousewheel.prevent :class="cM ? 'closeM' : ''">
            <div class="hmBox">
                <a :href="getAdvData(2).children[0].url" target="_blank" rel="noopener noreferrer">
                    <img class="hmImgs" :src="getAdvData(2).children[0].image" alt="" />
                </a>
                <div class="hmBtn" @click="closeMask">
                    跳过 <span>{{ time }}</span>
                </div>
            </div>
        </div>
        <div class="smallBox" v-show="boxShow" v-if="getAdvData(2).children[1].image">
            <a :href="getAdvData(2).children[1].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(2).children[1].image" />
            </a>
            <div @click="boxShow = false">x</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "homeMask",
    inject: ["getAdvData"],
    data() {
        return {
            cM: false,
            showM: true,
            maskImg: "",
            time: 5,
            timer: null,
            boxShow: false,
        };
    },
    methods: {
        // 关闭弹窗
        closeMask() {
            this.cM = true;
            clearInterval(this.timer);
            this.boxShow = true;
        },
    },
    mounted() {
        // 3秒关闭广告
        this.timer = setInterval(() => {
            this.time--;
            if (this.time < 0) this.closeMask();
        }, 1000);
    },
};
</script>

<style lang="less" scoped>
.homeMask {
    width: 100%;
    height: 100%;
    background-color: rgba(126, 122, 122, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    opacity: 1;
    .hmBox {
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        height: 80vh;
        width: 60vw;
        // max-width: 1280px;
        // min-width: 600px;
        // max-height: 80vh;
        & > a {
            display: block;
            width: 100%;
            height: 100%;
        }
        .hmImgs {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 20px;
        }
        .hmBtn {
            // width: 40px;
            height: 40px;
            line-height: 40px;
            z-index: 666;
            text-align: center;
            font-weight: 900;
            font-size: 12px;
            color: white;
            // padding-top: 10px;
            box-sizing: border-box;
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
            // background: darkgray;
            background-color: rgba(126, 122, 122, 0.5);
            // opacity: 0.5;
            border-radius: 50%;
            padding: 0 10px;
        }
    }
}
.closeM {
    transform-origin: 100% 180px;
    transition: all 0.6s;
    transform: scale(0.01);
    opacity: 0;
    z-index: -1;
    visibility: hidden;
}
.smallBox {
    width: 120px;
    height: 80px;
    position: fixed;
    top: 150px;
    right: 12px;
    z-index: 9998;
    display: block;
    & > a img {
        width: 100%;
    }
    & > div {
        position: absolute;
        font-size: 16px;
        color: white;
        width: 20px;
        height: 20px;
        line-height: 16px;
        border-radius: 50%;
        text-align: center;
        right: -10px;
        top: -10px;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
}
</style>

<template>
    <div>
        <!-- 灯笼 -->
        <denglong v-if="getWebData().festival == '春节'"></denglong>
        <!-- 下雪 -->
        <xiaxue v-if="getWebData().festival == '下雪'"></xiaxue>
        <!-- 红包雨 -->
        <hongbaoyu v-if="getWebData().festival == '红包雨'"></hongbaoyu>
    </div>
</template>

<script>
import denglong from "./Festival/denglong.vue";
import Hongbaoyu from "./Festival/hongbaoyu.vue";
import Xiaxue from "./Festival/xiaxue.vue";

export default {
    inject: ["getWebData"],
    components: { denglong, Xiaxue, Hongbaoyu },
};
</script>

<style></style>

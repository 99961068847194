var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((350),function(el){return _c('div',{key:el,ref:"snow",refInFor:true,staticClass:"snow",class:'dh' + Math.floor(_vm.sj(1, 5)),style:({
            width: ((Math.floor(_vm.sj(5, 30))) + "px"),
            left: ((Math.floor(_vm.sj(0, 100))) + "vw"),
            // 动画时间
            'animation-duration': ((_vm.sj(15, 35)) + "s"),
            // 动画贝塞尔曲线
            'animation-timing-function': ("cubic-bezier(" + (_vm.sj(0, 1)) + ", " + (_vm.sj(0, 1)) + ", " + (_vm.sj(0, 1)) + ", 1)"),
        })},[_c('img',{attrs:{"src":require("@/assets/img/snow.png"),"alt":""}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="hb">
        <div class="yyy">
            <img src="@/assets/img/hob-inlet-03-v2.png" alt="" />
            <img src="@/assets/img/hob-inlet-02-v2.png" alt="" />
            <img src="@/assets/img/hob-inlet-01-v2.png" alt="" />
        </div>
        <div class="yu" :class="status ? 'hui' : ''">
            <span ref="span">点击关闭</span>
            <div>
                <img
                    v-for="el in 30"
                    :key="el"
                    :style="{
                        width: `${Math.floor(sj(40, 100))}px`,
                        left: `${Math.floor(sj(0, 100))}%`,
                        // 动画时间
                        'animation-duration': `${sj(1, 3)}s`,
                        // 动画延迟
                        'animation-delay': `${sj(0, 1)}s`,
                    }"
                    src="@/assets/img/hb.png"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            status: false,
            time: 0,
        };
    },
    mounted() {
        this.$nextTick(() => {
            let timer = setInterval(() => {
                this.time++;
                // 防止vue更新模板导致中断动画问题才怎么写
                this.$refs.span.innerText = "点击关闭" + this.time;
                if (this.time == 5) {
                    clearInterval(timer);
                    this.status = true;
                }
            }, 5000);
        });
    },
    methods: {
        sj(min, max) {
            let n = Math.random() * (max - min) + min;
            return n;
        },
    },
};
</script>

<style lang="less" scoped>
.hb {
    position: fixed;
    z-index: 3001;
    width: 100px;
    top: 160px;
    right: 12px;
    .yyy {
        &:hover {
            img:nth-of-type(2) {
                animation: hob 1s;
                @keyframes hob {
                    10%,
                    30%,
                    50% {
                        -webkit-transform: translateY(-10px);
                        transform: translateY(-10px);
                    }
                    20%,
                    40% {
                        -webkit-transform: translateY(-5px);
                        transform: translateY(-5px);
                    }
                    60%,
                    100% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                    }
                }
            }
        }
        img {
            width: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
        }
        img:nth-of-type(1) {
            z-index: 1;
        }
        img:nth-of-type(2) {
            z-index: 2;
            animation: hob_shake 1s infinite;
            @keyframes hob_shake {
                15%,
                45% {
                    -webkit-transform: rotate(3deg);
                    transform: rotate(3deg);
                    transform-origin: 50% 100%;
                }
                30% {
                    -webkit-transform: rotate(-3deg);
                    transform: rotate(-3deg);
                    transform-origin: 50% 100%;
                }
                60%,
                100% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                    transform-origin: 50% 100%;
                }
            }
        }
        img:nth-of-type(3) {
            z-index: 3;
        }
    }
    .yu {
        position: fixed;
        z-index: 3100;
        width: 120%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        transition: 1s;
        transform-origin: 80% 20%;
        opacity: 1;
        & > span {
            color: white;
            font-size: 24px;
            position: absolute;
            left: 5%;
            top: 5%;
        }
        & > div {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translate(-10%, -10%) rotate(-20deg);
            text-align: left;
            img {
                animation: an linear infinite;
                position: absolute;
                top: -30%;
                @keyframes an {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(180vh);
                    }
                }
            }
        }
    }
    .hui {
        transform: scale(0.2);
        opacity: 0;
    }
}
</style>
